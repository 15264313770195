<template>
  <div class="animated fadeIn">
    <b-card>
      <div class="card-body">
        <b-tabs content-class="mt-3" justified>
          <b-tab title="Overview" ref="overviewTab" active>
            <form>
              <b-row>
                <b-col lg="3" md="3">
                  <inline-input
                    :id="controls.user_name.id"
                    :value="data.user_name"
                    :label="controls.user_name.label"
                    :readonly="true"
                    :mode="mode"
                    :required="true"
                  />
                </b-col>
                <b-col lg="3" md="3">
                  <inline-date-picker
                    :id="controls.report_date.id"
                    :value-single="data.report_date"
                    :label="controls.report_date.label"
                    :readonly="controls.report_date.readonly"
                    :mode="mode"
                    @changed="updateDateField"
                  />
                </b-col>
                <b-col lg="3" md="3">
                  <inline-select
                    :id="controls.department.id"
                    :value="data.department"
                    :label="controls.department.label"
                    :readonly="controls.department.readonly"
                    :options="controls.department.options"
                    :allow-empty="false"
                    :mode="mode"
                    @changed="updateSelect"
                    :required="true"
                  />
                </b-col>
                <b-col lg="3" md="3">
                  <inline-select
                    :ref="controls.category.id"
                    :id="controls.category.id"
                    :value="data.category"
                    :label="controls.category.label"
                    :readonly="controls.category.readonly"
                    :options="controls.category.options"
                    :allow-empty="false"
                    :mode="mode"
                    @changed="updateSelect"
                    :required="true"
                  />
                </b-col>
              </b-row>
              <b-row v-if="mode === $constants.FORM_MODE.CREATE">
                <b-col>
                  <label class="col-form-label">Reporting Periods</label>
                  <clip-loader
                    v-if="isSubmittedReportsLoading"
                    class="ml-3 mt-2"
                    :size="14"
                    color="#36D7B7"
                    :loading="true"
                  />

                  <!-- <div v-if="submittedReports.length > 0" class="mt-3"></div> -->
                  <div v-if="!isSubmittedReportsLoading" class="mt-3">
                    <b-button
                      squared
                      v-for="(report, index) in reportsForSubmit"
                      :key="`rep-${index}`"
                      :variant="report.submitted ? 'success' : 'outline-dark'"
                      @click="
                        report.submitted
                          ? report.open(report.submitted)
                          : report.method(report.period)
                      "
                    >
                      {{ report.periodTitle }}
                    </b-button>
                    <!-- :disabled="report.submitted" -->
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="3" md="4">
                  <inline-date-picker
                    :id="controls.from_date.id"
                    :value-single="data.from_date"
                    :label="controls.from_date.label"
                    :readonly="true"
                    :mode="mode"
                    @changed="updateDateField"
                  />
                </b-col>
                <b-col lg="3" md="4">
                  <inline-date-picker
                    :id="controls.to_date.id"
                    :value-single="data.to_date"
                    :label="controls.to_date.label"
                    :readonly="true"
                    :mode="mode"
                    @changed="updateDateField"
                  />
                </b-col>
              </b-row>
              <b-row
                v-if="
                  data.category &&
                    data.category.label === 'Daily Production Report'
                "
              >
                <b-col lg="12">
                  <label class="col-form-label" :for="controls.editor.id"
                    >Report content</label
                  >
                  <div class="daily-content p-2" v-html="data.view_content" />
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12">
                  <label
                    v-if="
                      data.category &&
                        data.category.label === 'Daily Production Report'
                    "
                    class="col-form-label"
                    :for="controls.editor.id"
                    >Append report</label
                  >

                  <label
                    v-if="
                      data.category &&
                        data.category.label !== 'Daily Production Report'
                    "
                    class="col-form-label"
                    :for="controls.editor.id"
                    >Report content</label
                  >

                  <ckeditor
                    ref="ckeditor"
                    :id="controls.editor.id"
                    :editor="editor"
                    :disabled="controls.editor.readonly"
                    v-model="data.edit_content"
                    :config="controls.editor.config"
                    @blur="onEditorBlur"
                  />

                  <!--
                        <inline-input :id="controls.content.id" :value="data.content" :label="controls.content.label" :readonly="false" :mode="mode" :required="true" :isTextArea="true" @changed="updateField" />
                  -->
                </b-col>
              </b-row>
            </form>
          </b-tab>

          <b-tab
            :title="controls.tabs.files.title"
            v-if="mode !== $constants.FORM_MODE.CREATE"
          >
            <files-container
              :module-id="$route.meta.module.id"
              :entity-id="id"
              @loaded="onFileContainerLoad"
            />
          </b-tab>
        </b-tabs>
        <hr />
        <span v-if="data.reviewed_by"
          ><b>Reviewed:</b> {{ data.reviewed_by }}</span
        >
        <span v-if="data.reviewed_at"> on {{ data.reviewed_at }}</span>

        <form-submission-actions
          :mode="mode"
          :loading="{
            save: saveInProgress,
            saveAndView: saveAndViewInProgress
          }"
          :buttons-visibility="{
            previous: $customTable.getPrevKey($route.meta.module, id),
            next: $customTable.getNextKey($route.meta.module, id)
          }"
          @previous-item="
            $router.push({
              name: $route.name,
              params: {
                action: $route.params.action,
                id: $customTable.getPrevKey($route.meta.module, id)
              }
            })
          "
          @next-item="
            $router.push({
              name: $route.name,
              params: {
                action: $route.params.action,
                id: $customTable.getNextKey($route.meta.module, id)
              }
            })
          "
          @save="
            save('tabular').then(response =>
              response ? $router.push({ name: 'Reports submission' }) : false
            )
          "
          @save-and-view="
            save('view').then(response =>
              response
                ? $router.push({
                    name: 'Report submission',
                    params: { action: 'view', id: response }
                  })
                : false
            )
          "
          @edit="
            $router.push({
              name: 'Report submission',
              params: { action: 'edit', id: id }
            })
          "
          @back="$router.push($store.getters['router/previousRoute'])"
          @custom-click="
            name => {
              this[name]()
            }
          "
          :custom-buttons="[
            {
              text: 'Mark as Reviewed',
              icon: 'signature',
              loading: false,
              visible: !data.reviewed && isReviewer,
              method: 'reviewed',
              variant: 'warning'
            }
          ]"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'

import { ClipLoader } from '@saeris/vue-spinners'

import InlineInput from '@/components/InlineInput'
import InlineSelect from '@/components/InlineSelect'
import InlineDatePicker from '@/components/InlineDatePicker'

import CKEditor from '@ckeditor/ckeditor5-vue'

import InlineEditor from '@ckeditor/ckeditor5-build-inline'

import FilesContainer from '@/components/FilesContainer'

Vue.use(CKEditor)

import FormSubmissionActions from '@/components/FormSubmissionActions'

import { mapState, mapActions } from 'vuex'

export default {
  name: 'ReportSubmissionForm',
  props: {
    id: { type: String, default: '' },
    action: { type: String, default: '' },
    pDepartmentId: { type: String, default: '' },
    pCategoryId: { type: String, default: '' },
    pFrom: { type: String, default: '' },
    pTo: { type: String, default: '' }
  },
  components: {
    InlineInput,
    InlineSelect,
    InlineDatePicker,

    ckeditor: CKEditor.component,
    'clip-loader': ClipLoader,
    FilesContainer,
    FormSubmissionActions
  },
  data: function () {
    return {
      mode: NaN,
      editor: InlineEditor,
      //editor: ClassicEditor,
      isLoading: false,
      isReviewer: false,
      controls: {
        tabs: {
          files: {
            title: 'Files'
          }
        },
        buttons: {
          reviewed: {
            id: 'button:set_reviewed'
          },
          edit: {
            id: 'button:details_edit'
          },
          save: {
            id: 'button:details_save'
          }
        },
        user_name: {
          id: 'input:user_name',
          label: 'User Name',
          changed: false,
          readonly: false
        },
        report_date: {
          id: 'datepicker:report_date',
          label: 'Report Date',
          changed: false,
          readonly: false
        },

        department: {
          id: 'select:department',
          label: 'Department',
          options: [],
          changed: false,
          readonly: false
        },
        category: {
          id: 'select:category',
          label: 'Category',
          options: [],
          changed: false,
          readonly: false
        },
        from_date: {
          id: 'datepicker:from_date',
          label: 'From Date',
          changed: false,
          readonly: false
        },
        to_date: {
          id: 'datepicker:to_date',
          label: 'To Date',
          changed: false,
          readonly: false
        },
        content: {
          id: 'input:content',
          label: 'Content',
          changed: false,
          readonly: false
        },
        editor: {
          data: '',
          config: {
            startupFocus: true,
            startupShowBorders: true
            // readOnly: true,
          },
          readonly: false
        }
      },
      data: {
        id: '',
        user_name: '',
        report_date: '',
        department: {
          id: '',
          label: ''
        },
        category: {
          id: '',
          label: '',
          frequency: ''
        },
        from_date: '',
        to_date: '',
        view_content: '',
        edit_content: '',
        content: '',
        reviewed: true
      },
      submittedReports: [],
      isSubmittedReportsLoading: false,
      reportsForSubmit: [],
      saveInProgress: false,
      saveAndViewInProgress: false
    }
  },
  computed: mapState({
    profile: state => state.profile
  }),
  created () {
    this.initialize()
  },
  mounted: function () {},
  methods: {
    ...mapActions('profile', ['fetchProfile']),
    onFileContainerLoad (count) {
      if (count > 0) this.controls.tabs.files.title = `Files (${count})`
    },

    initialize: async function () {
      let self = this

      if (this.action === 'create') {
        this.mode = this.$constants.FORM_MODE.CREATE
      } else if (this.action === 'edit') {
        this.mode = this.$constants.FORM_MODE.EDIT
      } else if (this.action === 'view') {
        this.mode = this.$constants.FORM_MODE.VIEW
      } else {
        await this.$form.msgBoxOk('Wrong request')
        return
      }

      this.updateControlsState()

      this.$api.get('users/reports/categories').then(response => {
        this.controls.category.options = response.map(item => {
          return {
            id: item.id,
            label: item.name,
            frequency: item.frequency
          }
        })

        //if props passed then set category
        if (self.action === 'create' && self.pCategoryId) {
          self.data.category = self.controls.category.options.find(
            i => i.id === this.pCategoryId
          )
        }
      })
      this.$api.get('users/reports/departments').then(response => {
        this.controls.department.options = response.map(item => {
          return {
            id: item.id,
            label: item.name
          }
        })

        //if props passed then set department
        if (self.action === 'create' && self.pDepartmentId) {
          self.data.department = self.controls.department.options.find(
            i => i.id === this.pDepartmentId
          )
        }
      })

      let s = this.$store.getters['userReports/getState']
      //let uid = s.user ? s.user.id : this.profile.id
      //console.log(JSON.stringify(s))
      //let uid = this.profile.id
      //05092024
      let uid = this.profile.data.id

      console.log('reportSubmissionForm.profile:', this.profile)

      this.isSubmittedReportsLoading = true
      this.$api.get(`users/${uid}/reports`).then(p => {
        this.isSubmittedReportsLoading = false
        self.submittedReports = p
        self.drawReportIntervals(self.data.category)
      })

      if (this.mode !== this.$constants.FORM_MODE.CREATE && this.id) {
        this.fetchReport(this.id)
      }

      this.data.reportId = this.id

      //this.data.user_name =  s.user ?  s.user.name : `${this.profile.data.first_name} ${this.profile.data.last_name}`;
      //20200215
      if (this.mode === this.$constants.FORM_MODE.CREATE)
        this.data.user_name = `${this.profile.data.first_name} ${this.profile.data.last_name}`

      if (this.mode === this.$constants.FORM_MODE.CREATE) {
        this.data.report_date = moment.utc().format('YYYY-MM-DD')

        //if props empty then read storage
        if (
          !this.pDepartmentId &&
          !this.pCategoryId &&
          !this.pFrom &&
          !this.pTo
        ) {
          this.data.from_date = s.period.startDate
          this.data.to_date = s.period.endDate
          this.data.department = s.department
          this.data.category = s.category
        } else {
          //department and category will be updated after loading dictionaries
          this.data.from_date = this.pFrom
          this.data.to_date = this.pTo
        }

        //this.data.user = s.user

        // this.$store.dispatch('userReports/clearState')
      }

      //api.get(`users/reports/${this.data.id}/reviewed`).then(response => {})
    },
    edit () {},
    reviewed () {
      this.$api
        .put(`users/reports/${this.data.id}/reviewed`)
        .then(response => {
          this.makeToast({
            title: 'Message',
            text: response.message,
            type: 'warning'
          })

          this.initialize()
        })
        .catch(error => {
          this.makeToast({
            title: 'Error',
            text: error.message,
            type: 'danger'
          })
        })
    },
    save (_mode) {
      if (!this.$form.testForm(this)) {
        this.$form.makeToastError('Form contains errors')
        return Promise.resolve(false)
      }

      this.saveInProgress = _mode === 'tabular'
      this.saveAndViewInProgress = _mode === 'view'

      let url =
        this.mode === this.$constants.FORM_MODE.CREATE
          ? 'users/reports/create'
          : 'users/reports/update'

      this.data.content = this.processContent()

      return this.$api
        .put(url, this.data)
        .then(response => {
          this.$form.makeToastInfo(response.message)

          this.saveInProgress = false
          this.saveAndViewInProgress = false

          this.$router.currentRoute.params.id = response.id

          return response.id
        })
        .catch(error => {
          this.$form.makeToastError(error.message)

          return Promise.resolve(false)
        })
    },
    onEditorBlur (e, data) {
      if (this.mode !== this.$constants.FORM_MODE.VIEW) return

      data.content = this.processContent()

      this.updateField('content', data.content, this.mode)
    },
    updateDateField (e) {
      this.updateField(e.id, e.valueSingleAsString)
    },
    updateField (field, value) {
      let self = this
      let params = {}
      params['id'] = this.id
      params[field] = value

      //update local data values - it can be used in Edit action
      self.data[field] = value

      //if control in View mode then update database
      if (this.mode === this.$constants.FORMCONTROLMODE.VIEW)
        this.$api
          .put('users/reports/update', params)
          .then(response => {
            this.makeToast({
              title: field,
              text: response.message,
              type: 'warning'
            })

            self.controls[field].changed = false

            this.initialize()
          })
          .catch(response => {
            console.log(response)

            this.makeToast({
              title: field,
              text: response.message,
              type: 'danger'
            })
          })
    },
    updateSelect (id, value) {
      this.updateField(id, value)
    },
    makeToast (data) {
      this.$bvToast.toast(data.text, {
        title: data.title,
        variant: data.type,
        toaster: 'b-toaster-bottom-right',
        autoHideDelay: 3000,
        solid: true
      })
    },
    fetchReport: async function (id) {
      let self = this

      return this.$api
        .get(`users/reports/${id}`)
        .then(response => {
          if (self.$_.isEmpty(response)) return
          console.log('response:', response)
          // 20200215
          self.data.user_id = response.submitter_id
          self.data.user_name = response.submitter_name
          //end 20200215

          // 20231026
          self.data.reviewed_by = response.reviewed_by
          self.data.reviewed_at = response.reviewed_at
          //end 20231026

          self.data.department = {
            id: response.department_id,
            label: response.department_name
          }
          self.data.category = {
            id: response.category_id,
            label: response.category_name,
            frequency: response.frequency
          }

          //self.drawReportIntervals(self.data.category);

          self.data.id = self.id

          if (self.data.category.label === 'Daily Production Report') {
            self.data.view_content = response.content
          } else {
            self.data.edit_content = response.content
          }

          self.data.from_date = response.reportperfrom
          self.data.to_date = response.reportperto
          self.data.report_date = response.reportdate

          self.data.reviewed = response.reviewed == '1'

          self.data.reviewers_id = response.reviewers_id
            ? response.reviewers_id.split(',')
            : []

          self.isReviewer = self.data.reviewers_id.includes(
            self.profile.data.id.toString()
          )

          //if report reviewed then disable editor
          self.controls.editor.readonly = self.data.reviewed
        })
        .catch(error => {
          console.log(error)

          this.$emit('message', {
            title: 'Error',
            text: error.message
          })
        })
    },
    testForm () {
      let result = true

      let parent = this.$refs.overviewTab

      for (let i = 0; i < parent.$children.length; i++) {
        if (parent.$children[i].test) {
          if (!parent.$children[i].test()) result = false
        }
      }

      return result
    },
    drawReportIntervals: function (category) {
      if (this.mode !== this.$constants.FORM_MODE.CREATE) return

      if (!category || !category.frequency) return

      this.reportsForSubmit = []

      let period = {
        from: '',
        to: ''
      }
      let title = ''
      let date = moment()

      let reportCount = 12

      if (category.frequency === this.$constants.REPORTS_FREQUENCY.DAILY)
        reportCount = 30
      if (category.frequency === this.$constants.REPORTS_FREQUENCY.WEEKLY)
        reportCount = 10
      if (category.frequency === this.$constants.REPORTS_FREQUENCY.BIWEEKLY)
        reportCount = 10
      if (category.frequency === this.$constants.REPORTS_FREQUENCY.MONTHLY)
        reportCount = 12
      if (category.frequency === this.$constants.REPORTS_FREQUENCY.QUARTERLY)
        reportCount = 4
      if (category.frequency === this.$constants.REPORTS_FREQUENCY.YEARLY)
        reportCount = 5

      //2016-11-07 it is a start of bi-weekly period
      let biWeeklyDatesArray = this.$helpers.getBiWeeklyDatesArray(
        process.env.VUE_APP_BI_WEEKLY_START
      )

      for (let i = 0; i < reportCount; i++) {
        if (category.frequency === this.$constants.REPORTS_FREQUENCY.DAILY) {
          title = moment(date).format('YYYY-MM-DD')
          period.from = moment(date).format('YYYY-MM-DD')
          period.to = moment(date)
            .add(1, 'day')
            .format('YYYY-MM-DD')

          date = date.subtract(1, 'day')
        }

        if (category.frequency === this.$constants.REPORTS_FREQUENCY.WEEKLY) {
          title = 'Week ' + moment(date).isoWeek()
          period.from = moment(date)
            .startOf('isoWeek')
            .format('YYYY-MM-DD')
          period.to = moment(period.from)
            .endOf('isoWeek')
            .format('YYYY-MM-DD')
          date = moment(date)
            .startOf('isoWeek')
            .subtract(1, 'week')
        }

        if (category.frequency === this.$constants.REPORTS_FREQUENCY.BIWEEKLY) {
          title =
            'Weeks ' +
            moment(biWeeklyDatesArray[i].startDate).isoWeek() +
            '&' +
            moment(biWeeklyDatesArray[i].endDate).isoWeek()
          period.from = biWeeklyDatesArray[i].startDate
          period.to = biWeeklyDatesArray[i].endDate
        }

        if (category.frequency === this.$constants.REPORTS_FREQUENCY.MONTHLY) {
          title = moment(date).format('MMMM')
          period.from = moment(date)
            .startOf('month')
            .format('YYYY-MM-DD')
          period.to = moment(period.from)
            .endOf('month')
            .format('YYYY-MM-DD')
          date = date.subtract(1, 'month')
        }

        if (
          category.frequency === this.$constants.REPORTS_FREQUENCY.QUARTERLY
        ) {
          title = 'Quarter ' + moment(date).quarter()
          period.from = moment(date)
            .startOf('quarter')
            .format('YYYY-MM-DD')
          period.to = moment(period.from)
            .endOf('quarter')
            .format('YYYY-MM-DD')
          date = date.subtract(1, 'quarter')
        }

        if (category.frequency === this.$constants.REPORTS_FREQUENCY.YEARLY) {
          let dd = date.clone().subtract(1, 'year')
          title = 'Year ' + moment(dd).year()
          period.from = moment(dd)
            .startOf('year')
            .format('YYYY-MM-DD')
          period.to = moment(period.from)
            .endOf('year')
            .format('YYYY-MM-DD')
          date = date.subtract(1, 'year')
        }

        let submitted = this.submittedReports.find(
          p =>
            p.From >= period.from &&
            p.To <= period.to &&
            p.category_id === category.id
        ) // !== undefined;

        let report = {
          //submitted: Math.random() >= 0.5,
          submitted: submitted,
          periodTitle: title,
          period: {
            ...period
          },
          method: p => {
            this.data.from_date = p.from
            this.data.to_date = p.to
          },
          open: report => {
            // console.log(report)
            /*
                                    let routeData = this.$router.resolve({
                                        name: "Report submission",
                                        params: {
                                            action: "view",
                                            id: report.ID
                                        }
                                    });
                                    window.open(routeData.href, "_blank");
                                    */
            this.$router.push({
              name: 'Report submission',
              params: {
                action: 'view',
                id: report.ID
              }
            })
          }
        }

        this.reportsForSubmit.push(report)
      }
    },
    updateControlsState: function () {
      //allow editing just in create mode

      let _readonly = true

      if (this.mode === this.$constants.FORM_MODE.CREATE) {
        _readonly = false
      }

      for (var prop in this.controls) {
        if (Object.prototype.hasOwnProperty.call(this.controls, prop)) {
          this.controls[prop].readonly = _readonly
        }
      }
    },
    processContent: function () {
      let content = ''

      if (this.data.category.label === 'Daily Production Report') {
        let footprint =
          '<strong>' +
          `${this.profile.data.first_name} ${this.profile.data.last_name}` +
          ' ' +
          this.$nowClient.format('YYYY-MM-DD HH:mm') +
          '</strong>'

        content = this.data.view_content + footprint + this.data.edit_content

        this.data.edit_content = ''
      } else {
        content = this.data.edit_content
      }

      return content
    }
  },
  watch: {
    'data.category': function (newVal) {
      this.drawReportIntervals(newVal)
    }
  }
}
</script>

<style scoped>
::v-deep .vue-daterange-picker {
  display: block !important;
}

::v-deep .reportrange-text {
  border: 1px solid #e4e7ea !important;
}

::v-deep .vue__time-picker input.display-time {
  border: 0px solid #d2d2d2;
  width: 7em;
  height: 2.2em;
  margin: -0.3em -0.5em;
}

::v-deep .ck.ck-editor__editable_inline {
  border: 1px solid #e8e8e8;
  min-height: 500px !important;
}

::v-deep .ck.ck-editor__editable_inline.ck-focused {
  background-color: #ffffed;
}

::v-deep .btn-outline-dark {
  border-color: #ccc;
}

::v-deep .daily-content {
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
}
</style>
